import { setExperimentAuthTokenInLocalStorage } from '@/localStorageManager'

export default function createExperimentModule () {
  const state = {
    experiment: null,
    token: ''
  }

  const getters = {
    experiment (state) {
      return state.experiment
    },
    getUserData (state) {
      return state.experiment.user
    },
    token (state) {
      return state.token
    },
    isReady (state) {
      if (!state.experiment) {
        return false
      }

      // Study is unprotected with password
      if (state.experiment && !state.experiment.isProtected) {
        return true
      }

      // Study is protected but token doesn't exist
      if (state.experiment && state.experiment.isProtected && state.token) {
        return true
      }

      return false
    }
  }

  const mutations = {
    setExperiment (state, newValue) {
      state.experiment = newValue
    },
    setToken (state, tokenString) {
      state.token = tokenString
      setExperimentAuthTokenInLocalStorage(tokenString)
    },
    resetExperiment (state) {
      state.token = ''
      state.experiment = null
    }
  }

  const actions = {
    setExperiment ({ commit }, experiment) {
      commit('setExperiment', experiment)
    },
    setToken ({ commit }, value) {
      commit('setToken', value)
    },
    resetExperiment ({ commit }) {
      commit('resetExperiment')
    }
  }

  return {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    namespaced: true
  }
}
