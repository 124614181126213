import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import { createProvider } from './vue-apollo'
import registerSimplePlugins from './plugins/simplePlugins.js'
import initToasted from './plugins/toasted.js'
import 'core-js/stable' // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime' // To ensure that regeneratorRuntime is defined globally

Vue.config.productionTip = false
registerSimplePlugins()
initToasted(Vue)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
