<template>
  <v-footer
    v-if="showFooter"
    color="#f5f5f5 lighten-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        color="primary"
        text
        rounded
        class="my-2"
        @click="openLinkInTab(dashboardLink)"
      >
        {{ $t('footer.createStudy') }}
      </v-btn>

      <v-btn
        color="primary"
        text
        rounded
        class="my-2"
        @click="openLinkInTab(reportAProblemLink)"
      >
        {{ $t('footer.reportBug') }}
      </v-btn>

      <v-btn
        color="primary"
        text
        rounded
        class="my-2"
        @click="openLinkInTab(featureRequestLink)"
      >
        {{ $t('footer.featureRequest') }}
      </v-btn>

      <v-col
        class="#f5f5f5 lighten-2 py-4 text-center black--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} —
        <strong><a class="footer__attribution" href="https://kardsort.com" target="_blank">kardSort.com</a></strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import config from '@/config'

export default {
  computed: {
    showFooter () {
      return this.$route.name === 'home'
    },
    dashboardLink () {
      return config.dashboardLink
    },
    featureRequestLink () {
      return config.featureRequestLink
    },
    reportAProblemLink () {
      return config.reportAProblemLink
    }
  },
  methods: {
    openLinkInTab (link) {
      window.open(link, '_blank')
    }
  }
}
</script>
<style lang="sass">
.footer__attribution
  text-decoration: none
</style>
