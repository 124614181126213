import Vue from 'vue'
import Vuex from 'vuex'
import createExperimentModule from '@/store/modules/experiment'

Vue.use(Vuex)

const debug = ['production', 'testing'].indexOf(process.env.NODE_ENV) === -1

export default new Vuex.Store({
  modules: {
    experiment: createExperimentModule()
  },
  strict: debug
})
