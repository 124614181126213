import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home.vue')
  },
  {
    path: '/:slug',
    props: route => ({
      slug: route.params.slug
    }),
    component: () => import('@/modules/experiment/ExperimentBase.vue'),
    children: [
      {
        path: '',
        name: 'experiment-home',
        component: () => import('@/modules/experiment/ExperimentPage.vue'),
        props: () => ({
          infoText: 'welcomeMessage'
        }),
        meta: {
          previousRoute: null,
          nextRoute: {
            name: 'pre_questions'
          }
        }
      },
      {
        path: 'questions/pre',
        name: 'pre_questions',
        component: () => import('@/modules/questions/QuestionsPage.vue'),
        meta: {
          previousRoute: {
            name: 'experiment-home'
          },
          nextRoute: {
            name: 'instructions'
          }
        }
      },
      {
        path: 'instructions',
        name: 'instructions',
        component: () => import('@/modules/experiment/ExperimentPage.vue'),
        props: () => ({
          infoText: 'instructions'
        }),
        meta: {
          previousRoute: {
            name: 'pre_questions'
          },
          nextRoute: {
            name: 'sort'
          }
        }
      },
      {
        path: 'sort',
        name: 'sort',
        component: () => import('@/modules/sort/SortPage.vue'),
        meta: {
          justifyLayout: 'start',
          previousRoute: {
            name: 'instructions'
          },
          nextRoute: {
            name: 'post_questions'
          }
        }
      },
      {
        path: 'questions/post',
        name: 'post_questions',
        component: () => import('@/modules/questions/QuestionsPage.vue'),
        meta: {
          previousRoute: {
            name: 'sort'
          },
          nextRoute: {
            name: 'save'
          }
        }
      },
      {
        path: 'save',
        name: 'save',
        component: () => import('@/modules/save/SavePage.vue'),
        meta: {
          justifyLayout: 'start',
          previousRoute: {
            name: 'post_questions'
          }
        }
      },
      {
        path: '/:slug/acknowledgement',
        name: 'acknowledgement',
        component: () => import('@/modules/acknowledgement/AcknowledgementPage.vue'),
        meta: {
          justifyLayout: 'start'
        }
      },
      {
        path: '*',
        name: 'page-not-found',
        props: {
          messageTranslationKey: 'errorCode.404'
        },
        component: () => import('@/components/ErrorPage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
