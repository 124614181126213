import Toasted from 'vue-toasted'

export function getMessageOrDefaultHandler (defaultMessage) {
  return (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return defaultMessage
    }

    // if there is a message show it with the message
    return payload.message
  }
}

/**
 * Initialize global message types for the given vue-toasted instance.
 *
 * See: https://github.com/shakee93/vue-toasted
 */
export function initGlobalToastedMessages (toasted) {
  const DEFAULT_TOAST_DURATION = 3000

  toasted.register('errorMessage', getMessageOrDefaultHandler({ defaultMessage: 'An error occurred' }), {
    duration: DEFAULT_TOAST_DURATION,
    type: 'error',
    icon: 'error'
  })

  toasted.register('stickyErrorMessage', getMessageOrDefaultHandler({ defaultMessage: 'An error occurred' }), {
    duration: null,
    type: 'error',
    icon: 'error',
    action: [{
      icon: 'close',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }]
  })

  toasted.register('successMessage', getMessageOrDefaultHandler({ defaultMessage: 'Success!' }), {
    duration: DEFAULT_TOAST_DURATION,
    type: 'success',
    icon: 'check_circle'
  })

  toasted.register('primary', getMessageOrDefaultHandler({ defaultMessage: 'Info!' }), {
    duration: DEFAULT_TOAST_DURATION,
    type: 'primary-message',
    icon: 'info'
  })
}

/**
 * Register `vue-toasted` with the default vue instance.
 */
export default function initToasted (vueInstance, ToastedPlugin = Toasted) {
  vueInstance.use(ToastedPlugin, {
    theme: 'toasted-primary',
    iconPack: 'material',
    position: 'top-right',
    keepOnHover: true
  })
  initGlobalToastedMessages(vueInstance.toasted)
}
