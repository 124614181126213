<template>
  <VApp>
    <AppBar />
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <RouterView />
      </v-container>
    </v-main>
    <Footer />
  </VApp>
</template>

<script>
import Footer from './components/Footer'
import AppBar from '@/modules/appBar/AppBar.vue'

export default {
  name: 'App',
  components: { AppBar, Footer }
}
</script>
