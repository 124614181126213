const isProd = window.location.hostname === 'study.kardsort.com'

const kardsortHome = '//kardsort.com'
let dashboardLink = '//localhost:1337'
let apiEndPoint = '//localhost:1337/graphql/'
if (isProd) {
  dashboardLink = '//dashboard.kardsort.com'
  apiEndPoint = '//dashboard.kardsort.com/graphql/'
}

const defaultConfig = {
  demoStudySlug: '/demo',
  dashboardLink,
  apiEndPoint,
  kardsortHome,
  experimentAuthToken: 'kardsort-study',
  reportAProblemLink: '//forms.gle/sMaYQMd6No1yRWhx5',
  featureRequestLink: '//forms.gle/TLUdP68Xq9a8brnn6'
}

/**
 * Configuration derived from defaults and a 'config' cookie sent alongside the
 * index.html requests.
 */

const config = {
  ...defaultConfig
}

export default config
