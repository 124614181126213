
<template>
  <router-link
    to="/"
    class="kardsort-logo"
  >
    <v-img
      alt="KardSort Logo"
      class="shrink"
      contain
      src="@/assets/kardsort-logo-resized.png"
      transition="scale-transition"
    />
  </router-link>
</template>
<script>
export default {
  name: 'Logo'
}
</script>
<style lang="sass">
.kardsort-logo
  padding-bottom: .5rem
</style>
