/**
 * Module for persisting to and initializing values from local storage
 */
import config from '@/config'

const EXPERIMENT_AUTH_TOKEN = config.experimentAuthToken

function getValueFromLocalStorage (defaultValue, storageKey) {
  const localStorageValue = localStorage.getItem(storageKey)
  if (localStorageValue) {
    return localStorageValue
  }
  return defaultValue
}

export function getExperimentAuthTokenFromLocalStorage () {
  return getValueFromLocalStorage(null, EXPERIMENT_AUTH_TOKEN)
}

export function setExperimentAuthTokenInLocalStorage (tokenString) {
  localStorage.setItem(EXPERIMENT_AUTH_TOKEN, tokenString)
}

export function removeExperimentAuthTokenInLocalStorage () {
  localStorage.removeItem(EXPERIMENT_AUTH_TOKEN)
}
