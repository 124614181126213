<template>

  <v-btn
    v-if="icon"
    @click="handleClick()"
    icon
    small
  >
    <v-icon :size="iconSize">{{ icon }}</v-icon>
  </v-btn>

  <v-btn
    v-else
    :color="buttonTextColor"
    :loading="loading"
    :disabled="disabled"
    :type="buttonType"
    :small="small"
    :block="block"
    :outlined="outlined"
    @click="handleClick()"
    :text="removeBackgroundColor">
    {{ this.buttonText || $t(translationKey) }}
  </v-btn>
</template>
<script>
import get from 'lodash/get'

export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    translationKey: {
      type: String,
      default: ''
    },
    removeBackgroundColor: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    routeTo: {
      type: Object,
      default: () => {}
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    icon: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: '15px'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonTextColor () {
      return this.removeBackgroundColor ? 'white' : this.color
    },
    routeToExists () {
      return get(this.routeTo, 'name', false) || get(this.routeTo, 'path', false)
    }
  },
  methods: {
    handleClick () {
      if (this.routeToExists) {
        this.$router.push(this.routeTo)
      }
      this.$emit('input')
    }
  }
}
</script>
