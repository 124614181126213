<template>
  <v-app-bar
    class="app-bar"
    fixed
    height="80px"
    app
    color="primary"
    dark
  >
    <Logo />
    <v-spacer />
    <template v-if="isReady">
      <Button
        v-if="previousRoute"
        translationKey="appBar.previousRouteButton"
        removeBackgroundColor
        :routeTo="previousRoute"
      />
      <Button
        v-if="nextRoute"
        translationKey="appBar.nextRouteButton"
        removeBackgroundColor
        :routeTo="nextRoute"
      />
    </template>
  </v-app-bar>
</template>

<script>
import config from '@/config'
import Button from '@/components/Button'
import { mapGetters } from 'vuex'
import Logo from './components/Logo'
import get from 'lodash/get'

export default {
  name: 'AppBar',
  components: { Button, Logo },
  computed: {
    ...mapGetters('experiment', ['isReady', 'experiment']),
    demoExperiment () {
      return {
        path: config.demoStudySlug
      }
    },
    nextRoute () {
      return this.validNextRoute(get(this.$route, 'meta.nextRoute', ''))
    },
    previousRoute () {
      return this.validNPreviousRoute(get(this.$route, 'meta.previousRoute', ''))
    }
  },
  methods: {
    validNextRoute (nextRoute) {
      const routeName = get(nextRoute, 'name', '')
      if (routeName === 'pre_questions') {
        const questions = get(this.experiment, 'preQuestionsSet', [])
        // questions exist
        if (questions.length) {
          return nextRoute
        }

        // if no questions exist
        const instructions = get(this.experiment, 'instructions', '')
        if (instructions.length) {
          return {
            name: 'instructions'
          }
        }
        // show sort page if no instruction has been set
        return {
          name: 'sort'
        }
      }

      if (routeName === 'instructions') {
        const instructions = get(this.experiment, 'instructions', '')
        // instructions are set
        if (instructions.length) {
          return {
            name: 'instructions'
          }
        }
        return {
          name: 'sort'
        }
      }

      if (routeName === 'post_questions') {
        const questions = get(this.experiment, 'postQuestionsSet', [])
        // questions exist
        if (questions.length) {
          return nextRoute
        }

        // if no questions exist
        return {
          name: 'save'
        }
      }
      return nextRoute
    },
    validNPreviousRoute (previousRoute) {
      const routeName = get(previousRoute, 'name', '')

      if (routeName === 'instructions') {
        const instructions = get(this.experiment, 'instructions', '')
        // instructions are set
        if (instructions.length) {
          return {
            name: 'instructions'
          }
        }

        const questions = get(this.experiment, 'preQuestionsSet', [])
        // questions exist
        if (questions.length) {
          return {
            name: 'pre_questions'
          }
        }
        // show sort page if no instruction has been set
        return {
          name: 'experiment-home'
        }
      }

      if (routeName === 'pre_questions') {
        const questions = get(this.experiment, 'preQuestionsSet', [])
        // questions exist
        if (questions.length) {
          return previousRoute
        }
        // show sort page if no instruction has been set
        return {
          name: 'experiment-home'
        }
      }

      if (routeName === 'post_questions') {
        const questions = get(this.experiment, 'postQuestionsSet', [])
        // questions exist
        if (questions.length) {
          return previousRoute
        }
        // show sort page if no instruction has been set
        return {
          name: 'sort'
        }
      }
      return previousRoute
    }
  }
}
</script>
